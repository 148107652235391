import React from 'react'

// Components
import Seemore from '../seemore'

const ContactCard = ({ content, itineraryText }) => (
  <div className="contact__card
                  col-lg-6">
    <h2 dangerouslySetInnerHTML={{__html: content.city}} />
    <div className="paragraph">
      <span className="paragraph bold"> {content.name} </span>
      <p>{content.email}</p>
      <p>{content.phoneNumber}</p>
    </div>
    <div className="address paragraph">
      <address dangerouslySetInnerHTML={{__html: content.address}} />
      <Seemore content={{
          textLink: itineraryText,
          link: content.adressLink
        }} rotated external/>
    </div>
  </div>
)

export default ContactCard
