import React from 'react'
import { graphql } from 'gatsby'

// Components
import Layout from '../layouts/index'
import Seo from '../components/seo'
import Wrapper from '../components/wrapper'
import ContactTop from '../components/contact/contact_top'
import ContactFollow from '../components/contact/contact_follow'
import ContactJoin from '../components/contact/contact_join'

const Contact = ({ data, path, pageContext }) => {
  const contact_content =  data.datoCmsContact.contact.find(el => el.model.apiKey === 'contact_content');
  const contact_cards = data.datoCmsContact.contact.filter(el => el.model.apiKey === 'contact_card');

  return (
    <Layout path={path} headerWhite={pageContext.headerWhite} locale={pageContext.locale} absolutePath={pageContext.absolutePath}>
      <Seo
        slug={data.datoCmsContact.slug.link}
        content={data.datoCmsContact.seo}
        lang={pageContext.locale}
        publication={data.datoCmsContact.meta}
        keywords={contact_cards.map((item) => item.city).join(', ')}
      />
      <React.Fragment>
        <Wrapper grid>
          <ContactTop content={contact_content}
                      cards={contact_cards} />
          <ContactFollow content={data.datoCmsContact.follow.find(el => el.model.apiKey === 'follow_content')}
                        socials={data.datoCmsContact.follow.find(el => el.model.apiKey === 'social_list')}/>
          <ContactJoin content={data.datoCmsContact.join[0]} />
        </Wrapper>
      </React.Fragment>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query ContactQuery($locale: String!) {
    datoCmsContact(locale: {eq: $locale}) {
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      seo {
        title
        description
        image {
          url
          height
          width
        }
        twitterCard
      }
      slug { link }
      contact {
        ... on DatoCmsContactContent {
          id
          headline
          paragraph
          itineraryText
          model {
            apiKey
          }
        }
        ... on DatoCmsContactCard {
          id
          email
          city
          name
          phoneNumber
          address
          adressLink
          model {
            apiKey
          }
        }
      }
      follow {
        ... on DatoCmsFollowContent {
          id
          headline
          paragraph
          model {
            apiKey
          }
        }
        ... on DatoCmsSocialList {
          id
          model {
            apiKey
          }
          socials {
            link
            copyName
            icon {
              url
            }
          }
        }
      }
      join {
        headline
        paragraph
        textLink
        link
      }
    }
  }
`
