import React from 'react'

// Components
import Seemore from '../seemore'

const ContactJoin = ({ content }) => {

return (
  <div className="contact__join row no-gutters">
    <div className="row no-gutters col-12">
      <div className="col-12
                      col-md-3
                      col-lg-3
                      col-xl-3">
        <h1 dangerouslySetInnerHTML={{__html: content.headline}} />
      </div>
      <div className="col-12
                      col-md-7 offset-md-2
                      col-lg-8 offset-lg-1
                      col-xl-7 offset-xl-1">
        <div className="col-lg-12 no-padding">
          <section className="paragraph"
              dangerouslySetInnerHTML={{__html: content.paragraph}} />
            <div className="references no-padding col-lg-12">
              <Seemore content={content} rotated external/>
            </div>
        </div>
      </div>
    </div>
  </div>
)
}

export default ContactJoin
