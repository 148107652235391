import React from 'react'

const ContactFollow = ({ content, socials }) => {
  return (
    <div className="contact__follow row no-gutters">
      <div className="row no-gutters col-12">
        <div className="col-12
                        col-md-3
                        col-lg-3
                        col-xl-3">
          <h1 dangerouslySetInnerHTML={{__html: content.headline}} />
        </div>
        <div className="col-12
                        col-md-7 offset-md-2
                        col-lg-8 offset-lg-1
                        col-xl-7 offset-xl-1">
          <div className="col-lg-12 no-padding">
            <section className="paragraph"
                     dangerouslySetInnerHTML={{__html: content.paragraph}} />
            <div className="socials no-padding col-lg-12">
              <ul>
                {socials.socials.map((item, index) => (
                  // Social icons
                  <li data-id={item.id}
                      key={`follow__socials-${index}`}>
                    <a className="paragraph"
                       href={item.link}
                       target="_blank"
                       rel="noopener noreferrer"
                       title={item.copyName}>
                      <img src={item.icon.url} alt={item.service} />
                      <span>{item.copyName}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactFollow
