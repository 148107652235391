import React from 'react'

// Components
import ContactCard from './contact_card'

const ContactTop = ({ content, cards }) => {
  return (
    <div className="contact__top row no-gutters">
      <div className="row no-gutters col-12">
        <div className="col-12
                        col-md-3
                        col-lg-3
                        col-xl-4">
          <h1 dangerouslySetInnerHTML={{__html: content.headline}}/>
        </div>
        <div className="col-12
                        col-md-7 offset-md-2
                        col-lg-8 offset-lg-1
                        col-xl-8 offset-xl-0">
          <div className="row no-gutters no-padding col-12">
            <div className="col-lg-12">
              <section className="paragraph"
                       dangerouslySetInnerHTML={{__html: content.paragraph}}/>
            </div>
            <div className="contact__card__wrapper row no-gutters col-lg-12">
              {cards.map((item, index) => (
                // Cards
                <ContactCard data-id={item.id}
                             key={`card__contact-${index}`}
                             itineraryText={content.itineraryText}
                             content={item}/>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactTop
